import React from 'react';

class About extends React.Component {
   
    render() {

        const lang = this.props.lang;
        const aboutInfo = this.props.aboutInfo;  
        const bannerInfo = this.props.bannerInfo; 
        
        const dataOfBirth = new Date(aboutInfo.dataOfBirth),
              today = new Date(),
              diff = today.getTime() - dataOfBirth.getTime(),
              years = Math.floor(diff / 31556736000),             
              yyyy = dataOfBirth.getFullYear();

        let  dd = dataOfBirth.getDate(),
             mm = dataOfBirth.getMonth() + 1;
         
        if (dd < 10) dd = '0' + dd;         
        if (mm < 10) mm = '0' + mm; 

        var bidrtDay = dd + '-' + mm + '-' + yyyy; 

      
        const total = this.props.exDetails,            
              exDetailsText =  total.years + " " + lang.yil + " " +  total.months  + " "+ lang.ay;

        return (
            <section className="fxt-about-section bg-color-1" id="about">
                <div className="container">
                    <div className="fxt-heading-layout1">
                        <h2 className="heading-title">{lang.about_about}</h2>
                        <div className="heading-paragraph">{aboutInfo.about}
                        </div>
                        <div className="heading-line"></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-12 has-animation">
                            <div className="fxt-about-box-layout1">
                                <div className="translate-zoomout-10 opacity-animation transition-50 transition-delay-500">
                                    <div className="item-img">                                       
                                        <img data-tilt="" src= { bannerInfo.userFoto} alt="About" />
                                    </div>                            
                                </div>
                                <div className="translate-bottom-50 opacity-animation transition-150 transition-delay-1000">
                                    <a href={aboutInfo.cvlink} target="blank" className="download-btn btn-fill">{lang.about_CVindir}
                                    <i className="flaticon-download"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-12">
                            <div className="fxt-about-box-layout2">
                                <h3 className="item-title">{bannerInfo.userName} {bannerInfo.userSurname}</h3>
                                <div className="item-subtitle">{aboutInfo.title}</div>
                                <p>{aboutInfo.aboutDetails} </p>
                                <div className="personal-info">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="info-list">
                                                <ul>
                                                    <li><span>{lang.about_dogumHarihi}:</span>{bidrtDay}</li>
                                                    <li><span>{lang.about_Sehir}:</span>{aboutInfo.city}</li>
                                                    <li><span>{lang.about_deneyim}:</span>{exDetailsText}</li>   
                                                    <li><span>{lang.about_Mail}:</span>{aboutInfo.mail}</li>  
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="info-list">
                                                <ul>
                                                    <li><span>{lang.about_Yas}:</span>{years}</li> 
                                                    <li><span>{lang.about_Lisans}:</span>{aboutInfo.lisans}</li>
                                                    <li><span>{lang.about_OnLisans}:</span>{aboutInfo.onlisans}</li>                                                                                                                                                            
                                                    <li><span>{lang.aboutInfo_bolum}:</span>{aboutInfo.bolum}</li>                                                                                                                                                                                                     
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
  }

 export default About;
  