import Banner from './Banner';
import Header from './Header';
import About from './About';
import Skills from './Skills';
import Certificate from './Certificate';
import Experience from './Experience';
import React, { Component } from "react";
import { withCookies } from "react-cookie";
import Contact from './Contact';

// import dbConfig from '../FireBaseConfig/db';
import analyticsConfig from '../FireBaseConfig/analytics';

import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import { onValue, ref } from "firebase/database";


class App extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      language: null,
      bannerInfo : {},
      aboutInfo: {},
      skills: [],
      experienceList :{},
      certificateList : {},
      userId : "ahmetbab",
      loading: true,            
      footerInfo: {},
      exTotalDetails : 0
    }           
  }  

  pageLoad = ()  => {      
    const userId = this.state.userId;  

    const app = initializeApp(analyticsConfig);
    const db = getDatabase(app);
    var query = ref(db, '/users/' + userId);

    onValue(query, (snapshot) => {
      var response = snapshot.val();
      if (snapshot.exists()) {
        const language = this.props.cookies.get("language") || response.defaultLanguage || "tr";

        query = ref(db, "/details_"+language+"/ahmetbab");
        onValue(query, (snapshot) => {
           var details = snapshot.val();                     

          const calculateExperience = (jobs) => {            
            let totalExperienceYears = 0;
            let totalExperienceMonths = 0;
          
            jobs.forEach((job) => {                
              const endDate = job.endDate === ""  ? new Date() : new Date(job.endDate);                            
              const startDate = new Date(job.startDate);
              const diff = Math.abs(endDate - startDate);

              const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
              const months = Math.floor((diff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
          
              totalExperienceYears += years;
              totalExperienceMonths += months;
            });
          
            // Ay hesabını yıl ve ay'a dönüştürme
            totalExperienceYears += Math.floor(totalExperienceMonths / 12);
            totalExperienceMonths = totalExperienceMonths % 12;
          
            return { years: totalExperienceYears, months: totalExperienceMonths };
          };                  
          
          const experience = calculateExperience(details.experienceList.experienceList);          
               
          this.setState(
          {                        
              aboutInfo : details.aboutInfo,
              experienceList : details.experienceList,
              footerInfo: {
                mail: details.aboutInfo.mail,
                country: details.aboutInfo.country,
              },
              exTotalDetails: experience
          });          
        });

        const bodyElement = document.body.classList;         
          bodyElement.remove('loaded');
          this.setState(
          { 
            userLanguage: response.defaultLanguage,         
            bannerInfo : response.bannerInfo,
            skills : response.skills,
            socailList : response.socialAdreess,              
            certificateList : response.certificateList,
            language: language                
          });  

          setTimeout(function(){                          
            const locHash = window.location.hash;             
                           
            this.setState( { activeLink: locHash , loading: false });  
            if(locHash) {                
              document.querySelectorAll("a[href='"+locHash+"']")[0].click();                   
            }  

            setTimeout(function(){   
                    
              bodyElement.add('loaded'); 

              if (!!window.IntersectionObserver) {
               
                let observer = new IntersectionObserver((entries, observer) => {                    
                    entries.forEach(entry => {                        
                        if (entry.isIntersecting) {
                            entry.target.classList.add("active-animation");                              
                            observer.unobserve(entry.target);
                        }
                    });
                }, {
                    rootMargin: "0px 0px -150px 0px"
                });
                document.querySelectorAll('.has-animation').forEach(block => {
                    observer.observe(block)
                });
              }
              else {
                  document.querySelectorAll('.has-animation').forEach(block => {
                      block.classList.remove('has-animation')
                  });
              }                                                                                 

            }, 500);                 
          }.bind(this), 750);    
      }
    });    
  };   
 
  componentDidMount(){    
    this.pageLoad ();
  } 

  //static propTypes = { cookies: instanceOf(Cookies).isRequired };  

  handleSetCookie = lang => {      
    if(this.state.language === lang) return false;    
    const { cookies } = this.props;  
    cookies.remove("language"); // remove the cookie
    cookies.set("language", lang, { path: "/" }); // set the cookie
    window.location.reload();
    // this.setState({ loading: true, language: cookies.get("language") });
    // this.pageLoad();
  };

  render() {      
    const language = this.state.language;

    if(!language || this.state.loading) 
      return (
        <div className="preloader">
            <div className="preloader-gif">
              {/* <img src= {"/"+window.location.origin + "/preloader.gif"} alt="Preloader" /> */}
              <img src= {"/preloader.gif"} alt="Preloader" />
            </div>
        </div>);    
 
    const lanFolder = require(`../locales/${language}/translation.json`); 
    
    return (
          <React.Fragment>
            <Header lang={lanFolder} activeLink={this.state.activeLink}/>
            <Banner lang={lanFolder} bannerInfo={this.state.bannerInfo} socailList = {this.state.socailList} handleSetCookie={this.handleSetCookie}/>                        
            <About lang={lanFolder} aboutInfo={this.state.aboutInfo} bannerInfo={this.state.bannerInfo} exDetails={this.state.exTotalDetails} />
            <Skills lang={lanFolder} skills={this.state.skills} />
            <Experience lang={lanFolder} experienceList={this.state.experienceList} />  
            <Certificate lang={lanFolder} certificateList={this.state.certificateList}/>
            <Contact lang={lanFolder} footerInfo={this.state.footerInfo}/>                                                
          </React.Fragment>
    );
  }
}

export default withCookies(App);