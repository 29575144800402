import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { CookiesProvider } from "react-cookie";

ReactDOM.render(
    <div className="wrapper">                                       
        <CookiesProvider>
           <App />
        </CookiesProvider>             
    </div> , 
document.querySelector('#body-mean-container'));
