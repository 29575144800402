



const  prodConfig   = {
        apiKey: "AIzaSyB7ka6goVZsUVfXw8GcjHi1ymz5ekTInp8",
        authDomain: "personalwebsite-20675.firebaseapp.com",
        projectId: "personalwebsite-20675",
        storageBucket: "personalwebsite-20675.appspot.com",
        messagingSenderId: "1042305872265",
        appId: "1:1042305872265:web:62e4cffca03437b742b158",
        measurementId: "G-THYDDX1ZTS"
  };

  const  devConfig    = {
        apiKey: "AIzaSyB7ka6goVZsUVfXw8GcjHi1ymz5ekTInp8",
        authDomain: "personalwebsite-20675.firebaseapp.com",
        projectId: "personalwebsite-20675",
        storageBucket: "personalwebsite-20675.appspot.com",
        messagingSenderId: "1042305872265",
        appId: "1:1042305872265:web:62e4cffca03437b742b158",
        measurementId: "G-THYDDX1ZTS"
    };

  const analytics =  process.env.NODE_ENV === 'production' ? prodConfig : devConfig;    
  export default analytics;
  