import React from 'react';

class Certificate extends React.Component {
   
    render() {

        const lang = this.props.lang;
        const certificateList = this.props.certificateList;
        
        const certificate = certificateList.map((n,g) => {                        
            return (
                <div className="col has-animation" key={g}>
                    <div className="translate-right-10 opacity-animation transition-200 transition-delay-100">
                        <div className="fxt-service-box-layout1">
                            <div className="item-img animation-delay0">                              
                                <img src= {"/certification/1/" +  n.logo} alt="service" />                                
                            </div>
                            <div className="item-content">
                                <h3 className="item-title"><a href={n.certificateUrl}>{n.Name}</a></h3>                                
                                <p className="m-0"><span>{lang.certificate_AlinanKurum}: </span> {n.institution} </p>
                                <p>                                  
                                    <span>{lang.certificate_VerildigiTarih} {n.History}</span>
                                </p>
                                <a href={n.certificateUrl} target="blank" className="item-btn">
                                    {lang.certificate_SertifikaGoruntule}</a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <section className="fxt-certificate-section" id="certificates">
                <div className="container">
                    <div className="fxt-heading-layout1">
                        <h2 className="heading-title">{lang.certificate_AldigimSertifikalar}</h2>                        
                        <div className="heading-line"></div>
                    </div>
                    <div className="experience row row-cols-2 row-cols-sm-2 row-cols-md-3 g-3">
                        {certificate}
                    </div>
                </div>
            </section>
        );
    }
}  

export default Certificate;
