import React from 'react';

class Experience extends React.Component {          
    render() {
        
        const lang = this.props.lang;

        const experienceList = this.props.experienceList;                                           

        const dateParseString = (startDate, endDate)  => { 
                        
            let pdate = new Date(startDate),                        
                years =pdate.getFullYear(),             
                mounth = pdate.getMonth(),
                text = "";                       

            text = lang["ay"+mounth] + "'" + years; 
            if(!endDate){
                text += " - " + lang.experience_present;
            }
            else{
                pdate = new Date(endDate);                            
                years =pdate.getFullYear();           
                mounth = pdate.getMonth();

                text +=  " - " + lang["ay"+mounth] + "'" + years; 
            }

            return (
                <h5 className="title title--h3 timeline__title">{text}</h5>
            );
        };       
       
        const education = experienceList.educationList.map((n) => {   
                     
            return (
                <article className="timeline__item" key={n.uniName}>
                    {dateParseString(n.startDate, n.endDate)}
                    <span className="timeline__period">{n.uniName} / {n.degree}</span>
                    <h3 className="designation">{n.episode}</h3>
                    <p className="timeline__description">{n.description}</p>
                 </article>
            );
        });

        const experience = experienceList.experienceList.map((n) => {            
            return (
                <article className="timeline__item" key={n.name}>
                    {dateParseString(n.startDate, n.endDate)}
                    <span className="timeline__period">{n.name}</span>
                    <h3 className="designation">{n.title}</h3>
                    <p className="timeline__description">{n.description}</p>
                 </article>
            );
        });

        return (
            <section className="fxt-about-section bg-color-1 has-animation" id="experience">
                <div className="container translate-left-10 opacity-animation transition-200 transition-delay-100">
                    <div className="fxt-heading-layout1">
                        <h2 className="heading-title">{lang.experience_Deneyim}</h2>
                        <div className="heading-paragraph">                       
                        </div>
                        <div className="heading-line"></div>
                    </div>      
                    <div className="experience row row-cols-1 row-cols-sm-2 row-cols-md-2 g-3">
                        <div className="section col">                    
                            <h2 className="title title--h2"><i className="fas fa-graduation-cap"></i>{lang.experience_Egitim}</h2>
                            <div className="timeline">                                
                               {education}
                            </div>	
                        </div>
                        <div className="section col">
                            <h2 className="title title--h2"><i className="fas fa-briefcase"></i>{lang.experience_Deneyim}</h2>
                            <div className="timeline">                            
                               {experience}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

    }
  }

 export default Experience;
  