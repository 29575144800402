import React from 'react';

class Header extends React.Component { 
  
  state = {width: 0, mobile: window.innerWidth < 992, sidebar: false, activeLink: "#wrapper"};  

  menuSelect(id) {    
    this.setState({ activeLink: id, sidebar: false });
  }

  updateDimensions() {
    const width = window.innerWidth;
    const stateControl = this.state.mobile;
    let mobileControl = width < 992;
    
    if((mobileControl && !stateControl) || (!mobileControl && stateControl))
    {       
      this.setState({ width: window.innerWidth, mobile:mobileControl });     
    }         
  };
 
  componentDidMount() {    
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }
    
  render() {   
    const showSidebar = () => {  
      this.setState({ sidebar: !this.state.sidebar});     
    };
    
    const lang = this.props.lang;
    const links = [
      {
        id: "#wrapper",
        text: lang.ev        
      },
      {
        id: "#about",
        text: lang.hakkimda
      },
      {
        id: "#skills",
        text: lang.yetenekler
      },
      {
        id: "#experience",
        text: lang.deneyimler
      },
      {
        id: "#certificates",
        text: lang.header_sertifikalar
      },
      {
        id: "#contact",
        text: lang.iletisim
      }
    ];      

    if(this.state.mobile)
    {
      return (
        <div className="mean-bar">
          <div className="mobile-menu-nav-back">
            <a className="logo-mobile" href="index.html">
              <img src="/image/logo/scroll-logo.png" alt="logo" className="img-fluid" />
            </a>
          </div>
          <div className="meanmenu-reveal" onClick={() => showSidebar() }><span></span><span></span><span></span></div>
          <nav className="mean-nav">
            <ul id="onepage_nav" className={this.state.sidebar ? 'nav-menu active' : 'nav-menu'}>
            {
              links.map(link => {

              return ( 
                <li className={this.state.activeLink === link.id ? "current" : ""} 
                    key={link.id} onClick={() => this.menuSelect(link.id)}>
                  <a href={link.id}>{link.text}</a>
                </li>
              );
            })}  
            </ul>
          </nav>
        </div>
      );
    }
    else{         
      return (      
        <header className="sticky-header menu-layout1">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-2">
                  <div className="logo-area">                   
                       <a href="index.html" className="default-logo">
                          <img src="/image/logo/logo.png" alt="logo" className="img-fluid" />
                      </a>
                      <a href="index.html" className="sticky-logo">
                        <img src="/image/logo/scroll-logo.png" alt="scroll-logo" className="img-fluid" />
                      </a>
                  </div>
              </div>
              <div className="col-lg-10 d-flex justify-content-end">
                  <nav id="dropdown" className="template-main-menu" style={{display: "block"}}>
                      <ul id="onepage_nav">
                      {
                        links.map(link => {

                        return ( 
                          <li className={this.state.activeLink === link.id ? "current" : ""} 
                              key={link.id} onClick={() => this.menuSelect(link.id)}>
                            <a href={link.id}>{link.text}</a>
                          </li>
                        );
                      })}                      
                      </ul>
                  </nav>
              </div>
          </div>
          </div>
        </header>
      );
    }
  }
}

export default Header;

// className={classnames("navbar", {
//   "navbar--hidden": !this.state.visible
// })}