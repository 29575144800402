import React from 'react';
  
class Banner extends React.Component {

    componentDidMount () {
        const itemList = document.querySelectorAll('.cd-words-wrapper b');
        let wrapper = document.querySelectorAll('.cd-words-wrapper')[0];
        let selectedElem,
            selectedItem = 0,
            beforeItem = 0,
            width;

        var timer = null;
        let sayac = 0;
        let ters = false;
        
        function icTimer(){             
            sayac = ters ? sayac - 2: sayac+ 3;          
            
            if(!ters && sayac > width)
            {
                ters = true;     
                setTimeout(icTimer, 1000);       
            }      
            else if (ters && sayac <= 0)
            {
                clearTimeout(timer); 
                ters = false;
                sayac = 0;
                wrapper.style.width = "0px";                
                setTimeout(disTimer, 1000);                
            }
            else
            {                       
                wrapper.style.width = sayac + "px";
                setTimeout(icTimer, 5);
            }           
        }
        
        function disTimer(){             
            let beforeElem = itemList[beforeItem];         

            beforeElem.classList.remove("is-visible");
            beforeElem.classList.add("is-hidden");
            
            selectedElem = itemList[selectedItem];
            selectedElem.classList.add("is-visible");  
            width = selectedElem.offsetWidth + 20;                         

            beforeItem = selectedItem;
            if (selectedItem === itemList.length - 1) selectedItem= 0;
            else selectedItem += 1;

            setTimeout(icTimer, 100);
        }

        setTimeout(disTimer, 2500);        
    }

    render() {
        const lang = this.props.lang, 
              bannerInfo= this.props.bannerInfo, 
              socailList= this.props.socailList;

        const langLoadHtml = (langlist) => {            
            if(langlist.length <= 1) return "";

            let langHtml;

            const langList =langlist.map((n) => {
                switch (n) {
                    case "en":  
                    langHtml = <img src="/language/english.png" alt={lang.banner_ingilizce} title={lang.banner_ingilizce} />;
                        break;
                
                    default:
                    langHtml = <img src="/language/turkey.png" alt={lang.banner_Turkish} title={lang.banner_Turkish} />;
                        break;
                }             
                return (
                    <div className="item translate-bottom-100 opacity-animation transition-50 transition-delay-1300" key={n} onClick={() => this.props.handleSetCookie(n) }>{langHtml}</div>
                );
            });

            return (
                <div className="item-lang"> {langList} </div>
            );
        }      

        const iconSelect = (ico, link) => {
            let icoSelect = "";
            switch(ico) {  
                case "facebook": icoSelect="fa-brands fa-facebook"; break;
                case "twitter": icoSelect="fab fa-twitter";break;
                case "instagram": icoSelect="fab fa-instagram";break;
                case "pinterest": icoSelect="fab fa-pinterest-p"; break;
                case "linkedin": icoSelect="fab fa-linkedin";break;
                case "github": icoSelect="fab fa-github";break;
                case "medium": icoSelect="fab fa-medium";break;
                case "hackerrank": icoSelect="fa-brands fa-hackerrank";break;                
                default: icoSelect = ""; break;
            }

            return <a href={link} target="blank"><i key={link} className={icoSelect}></i></a>;
        }
        
        const valid = /^(ftp|https):\/\/[^ "]+$/;
        const socailListItems =  Object.entries(socailList).map(([key,value]) => {    

            if(!value) return "";
            var control = new RegExp(valid).test(value);
            if(!control) return "";

            return (
                <li key={key}>
                    <div className="translate-bottom-100 opacity-animation transition-50 transition-delay-1300">               
                        {iconSelect(key,value)}  
                    </div>
                </li>
            );
        });        

        return (
            <section className="fxt-main-banner-section bg-common" id="wrapper">
                <div className="container">
                    <div className="fxt-main-banner-box-layout1 has-animation">
                        <div className="top-content">                    
                            <div className="item-img">
                                <div className="translate-zoomout-10 opacity-animation transition-50 transition-delay-100">
                                    <img src= {bannerInfo.userFoto} alt="Figure" />
                                </div>
                            </div>
                            <div className="item-content">
                                <div className="translate-bottom-100 opacity-animation transition-150 transition-delay-500">
                                    <div className="item-top-text">{lang.bannermerhaba},</div>
                                </div>
                                <div className="translate-bottom-100 opacity-animation transition-150 transition-delay-800">
                                    <h1 className="item-title">{lang.banneriam} {bannerInfo.userName} {bannerInfo.userSurname}</h1>
                                </div>
                                <div className="translate-bottom-100 opacity-animation transition-150 transition-delay-1100">
                                    <div className="cd-intro">
                                        <h2 className="small-text cd-headline clip">
                                            <span className="item-subtitle">A </span>
                                            <span className="cd-words-wrapper" style={{ width: "0px"}}>
                                                <b className="item-subtitle is-visible">Back-end developer</b>                                             
                                                <b className="is-hidden">Web Developer</b>
                                            </span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-content">                        
                            {langLoadHtml(bannerInfo.supportLang)}
                            <div className="item-social">                        
                                <ul>
                                    {socailListItems}
                                </ul>
                            </div>
                            <div className="item-website">
                                <div className="translate-bottom-100 opacity-animation transition-50 transition-delay-2400">                                                       
                                    <span> www.ahmetbabali.com </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
  }


export default Banner;
