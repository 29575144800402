import React from 'react';


const Skills = ({lang, skills}) => {  

    const listItems = skills.map((obj,index) =>                      
            <li className="single-item col-lg-4 col-md-6 col-sm-6 col-xs-12" key={index}>
                <label>{obj.name}</label>
                <div className="progress">
                    <div className="progress-bar wow slideInLeft" 
                        data-wow-delay="0ms" data-wow-duration="2000ms" role="progressbar"
                        style={{width: obj.point + "%"}}>
                        <span>{obj.point}%</span>
                    </div>
                </div>                                   
            </li>     
        );
   
    return (
        <section className="fxt-skill-section has-animation" id="skills">
            <div className="container translate-right-10 opacity-animation transition-200 transition-delay-100">  
                <div className="fxt-heading-layout1">
                    <h2 className="heading-title">{lang.yetenekler}</h2>
                    <div className="heading-paragraph">                       
                    </div>
                    <div className="heading-line"></div>
                </div>              
                <div className="row">                   
                    <div className="col-lg-12">
                        <div className="fxt-skill-wrap-layout1">                           
                            <ul className="fxt-skill-box-layout1">
                                {listItems}                               
                            </ul>
                        </div>
                    </div>                      
                </div>
            </div>
        </section>
    );
}
   

export default Skills;
