 
const Contact  = ({lang,footerInfo}) => {
    
    return (
        <footer id="contact">
        <div className="footer-info">
            <div className="container text-center">
                <div className="row text-center info">
                  
                    <div className="col-sm-6 item">
                        <div className="content">
                        <span className="pe-7s-call pe-3x pe-va icon"><i className="fas fa-envelope"></i></span>
                            <div>
                                <p><a href="mailto:your@example.com">{footerInfo.mail}</a></p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 item">
                        <div className="content">
                        <span className="pe-7s-call pe-3x pe-va icon"><i className="fas fa-map-marker-alt"></i></span>
                            <div>
                                <p>{footerInfo.country}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div> 
        </div>
        <div className="footer-bottom">
            <div className="container text-center">                
                <p> Copyright © 2020. {lang.footer_TelifHaklari}</p>               
            </div>
        </div>
    </footer>

    )
}

export default Contact;